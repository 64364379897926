import React from "react"
import ArticleContentBody from "../../components/pages/ArticleDetails/ArticleContentBody"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const ApplicationTerminatesWhenSentToBackground = () => {
  return (
    <Layout>
      <Seo
        title="How to Fix Application Terminates When Sent to Background"
        description={`Some users experience their applications being terminated when the phone goes into the background.`}
      />

      {/* This slug is matched with articles in Content */}
      <ArticleContentBody slug={`application-terminates-when-sent-to-background`} />
    </Layout>
  )
}

export default ApplicationTerminatesWhenSentToBackground
